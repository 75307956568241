<div class="footer" [ngClass]="{'footer-dark':darkModeService.getDarkMode()}">
  <div class="logo">
    <img src="../../../assets/images/logo_l.svg" />
    <img src="../../../assets/images/logo_r.svg" />
  </div>
  <span>This site is part of <a href="https://makerdao.com/en/ecosystem" target="_blank">MakerDAO</a> ecosystem</span>
  <div class="icons">
    <span>
      <a class="social" href="https://github.com/makerdao/mips" target="_blank">
        <img src="../../../assets/images/github.svg" />
        GitHub
      </a>
    </span>
    <span>
      <a class="social" href="https://forum.makerdao.com/" target="_blank">
      <img class="forum" src="../../../assets/images/forum_white.svg" />
      Forum
      </a>      
    </span>
    <span>
      <a class="social" href="https://vote.makerdao.com/" target="_blank">
      <img class="vote" src="../../../assets/images/voters-dark.svg" />
      Voting
      </a>      
    </span>
  </div>
</div>
